import { useDispatch, useSelector } from "react-redux";
import "./AddCode.css";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { subscribersAction } from "../../redux/slices/subscribersSlice";
import { addCode } from "../../redux/apiCalls/subscribersApiCall";
import { toast } from "react-toastify";
import { coursesAction } from "../../redux/slices/coursesSlice";
import { getAllCourses } from "../../redux/apiCalls/coursesApiCall";

const AddCode = ({currentPage, setCurrentPage}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [courseId, setCourseId] = useState("")

  const { loading } = useSelector((state) => state.subscriber);
  const { allCourse } = useSelector((state) => state.course);


  const handleAddCourse = () => {
    if (count.trim === "") {
      return toast.error("أدخل الكود");
    }
    console.log(courseId);
    const Code = { courseId, count};
    dispatch(addCode(Code, currentPage, setCurrentPage));
    setCount(0);
    setCourseId("");
  };

  useEffect(()=> {
    dispatch(getAllCourses())
  }, [])

  return (
    <div className="dash-users-layout ">
      <div className="check add-code-lay">
        <div className="add-new">
          <p>إضافة كود جديد ؟</p>
          <img
            src="/assests/deleteIcon.png"
            alt=""
            onClick={() => {
              dispatch(subscribersAction.setIsOpenedSubscribers());
            }}
          />
        </div>
        <img src="/assests/codeImg.png" />
        <form className="form form-dash">
          <label>أدخل الكود</label>
          <div className="box-input">
              <label
                className="flex items-end mb-[12px] text-[21px] font-[500] text-black  cursor-text"
                for="اسم المادة"
              >
                {/* <span>اسم المادة</span> */}
              </label>
              <select
                className="select"
                id="اسم المادة"
                value={courseId}
                onChange={(e)=> {
                  setCourseId(e.target.value)
                }}

              >
                <option value="" hidden></option>
                {allCourse.map((e) => {
                  return <option value={e.id}>{e.courseName}</option>
                })}
              </select>
            </div>
          <input
            value={count}
            onChange={(e) => {
              setCount(e.target.value);
            }}
            type="text"
            placeholder="أضف رقم"
          />

          <div className="btn" onClick={handleAddCourse}>
            {loading ? (
              <RotatingLines
                strokeColor="#fff"
                visible={true}
                height="30"
                width="30"
                color="#fff"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "اضافة"
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCode;
