import axios from "axios";

// http://architechproj-002-site1.anytempurl.com/api/User/LogIn

const request = axios.create({
  // baseURL: "http://architechsecond-002-site1.htempurl.com/api",
  baseURL: "https://architechsecond-002-site1.htempurl.com/api",
});

export const loginRequest = axios.create({
  baseURL: "https://architechsecond-002-site1.htempurl.com/api/User",
});

export default request;
