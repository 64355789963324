import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    numberOfNotifications: 0,
    isOpenedNotifications: false,
    loading: false,
  },

  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setNumberOfNotifications(state, action) {
      state.numberOfNotifications = action.payload;
    },
    removeNotifications(state, action) {
      const noti = action.payload;
      state.notifications = state.notifications.filter(
        (not) => not.id !== noti
      );
      state.numberOfAppUsers--;
    },
    setIsOpenedNotifications(state) {
      state.isOpenedNotifications = !state.isOpenedNotifications;
    },
    setLoading(state) {
      state.loading = !state.loading;
    },
  },
});

const notificationsReducer = notificationsSlice.reducer;
const notificationsAction = notificationsSlice.actions;

export { notificationsAction, notificationsReducer };
