import request from "../../utils/request";
import { settingsActions } from "../slices/settingsSlice";
import { toast } from "react-toastify";

export function getSettings(setWhoValue, setPrivacyValue) {
  return async (dispatch) => {
    try {
      const { data } = await request.get("/Setting/GetAllSettings");
      dispatch(settingsActions.setWho(data[0].aboutUs));
      setWhoValue(data[0].aboutUs);
      dispatch(settingsActions.setPrivacy(data[0].privacy));
      setPrivacyValue(data[0].privacy);
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateSettings(user) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoading());
      await request.put(
        `/Setting/UpdateSetting/?id=28e10efe-9b29-4463-2a43-08dc5af399c0`,
        user
      );

      toast.success("تم حفظ التغيرات");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(settingsActions.setLoading());
    }
  };
}
