import React from "react";

const Video = ({ videoUrl }) => {
  return (
    <video className="video" controls>
      <source src={videoUrl} type="video/mp4" />
    </video>
  );
};

export default Video;
